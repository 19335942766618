import React, { useEffect, useMemo, useState } from 'react';
import { Button, Former, LoadingIndicator, Modal, useClient } from '@digi-tim-19/components';
import * as S from './styles';
import styled from 'styled-components';
import MaskedInput from 'antd-mask-input';
import { uniqBy } from 'lodash';
import { useDimensions } from '@digi-tim-19/utils/build';
import { Radio } from 'antd';
import { FormApi, AnyObject } from 'final-form';
import { RadioChangeEvent } from 'antd/lib/radio';

export interface TableMeudiaPdv {
  _id: string;
  regional: string;
  uf: string;
  municipio: string;
  canal: string;
  bairro: string;
  loja: string;
  endereco: string;
  complemento: string;
  cep: string;
  tipo: string;
  createdAt: Date;
  __v: number;
  id: string;
  manha1011: number;
  manha1112: number;
  tarde1415: number;
  tarde1516: number;
  tarde1617: number;
}

export const Search = styled(MaskedInput)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

const normalize = (str: string) =>
  str
    ?.normalize?.('NFD')
    ?.replace?.(/[\u0300-\u036f]/g, '')
    ?.toUpperCase();

const enderecoDefaultValues = {
  endereco: '',
  complemento: '',
  cep: '',
  tipo: '',
};

const valuesWatchConfig = {
  regional: '',
  canal: '',
  tipo: '',
  loja: '',
  cep: '',
  uf: '',
  municipio: '',
  bairro: '',
  endereco: '',
};

const vagasMock = [
  {
    label: 'MANHÃ - 10h às 11h',
    value: '10-11',
    disabled: false,
    vagas: null,
  },
  {
    label: 'MANHÃ - 11h às 12h',
    value: '11-12',
    disabled: false,
    vagas: null,
  },
  {
    label: 'TARDE - 14h às 15h',
    value: '14-15',
    disabled: false,
    vagas: null,
  },
  {
    label: 'TARDE - 15h às 16h',
    value: '15-16',
    disabled: false,
    vagas: null,
  },
  {
    label: 'TARDE - 16h às 17h',
    value: '16-17',
    disabled: false,
    vagas: null,
  },
];
interface IPropsRadioButomCustom {
  options: {
    label: string;
    value: string;
    disabled: boolean;
    vagas: number;
  }[];
  form: FormApi<AnyObject>;
  setIsComplete: (value: boolean) => void;
  isSubmit: boolean;
}

export const RadioButtonCustomVaga = ({ options, form, setIsComplete, isSubmit }: IPropsRadioButomCustom) => {
  const [validate, setValidate] = useState<boolean>(true);

  const onChange = (e: RadioChangeEvent) => {
    form.change('vaga', e.target.value);
    if (form.getFieldState('vaga')?.value) setIsComplete(true);
  };

  useEffect(() => {
    if (isSubmit && !form.getFieldState('vaga')?.value) {
      setValidate(false);
    } else {
      setValidate(true);
    }
  }, [isSubmit, form.getFieldState('vaga')?.value]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      }}
    >
      <p style={{ margin: 0 }}>
        Selecione o horário de sua participação. Caso a opção desejada não esteja habilitada é porque suas vagas foram
        preenchidas. Selecione outro horário ou escolha outra PDV.{' '}
      </p>
      <Radio.Group
        onChange={onChange}
        value={form.getFieldState('vaga')?.value}
        style={{
          ...(validate
            ? {}
            : {
                border: '1px solid #dc3545',
                boxShadow: '0 0 0 1px #dc3545',
                opacity: 0.5,
              }),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          border: '2px solid #e8e8e8',
          borderRadius: '2px',
          padding: '2px 5px',
        }}
      >
        {options.map((option, idx) => (
          <Radio
            style={{ background: idx % 2 === 0 ? '#e6f4ff' : '#cce9ff' }}
            disabled={option.disabled}
            key={`radio-btn-${idx}`}
            value={option.value}
          >
            {`${option.label}`}
          </Radio>
        ))}
      </Radio.Group>
      {!validate && (
        <p
          style={{
            color: '#dc3545',
            alignSelf: 'end',
            fontSize: '11px',
            fontWeight: 'bold',
          }}
        >
          CAMPO OBRIGATÓRIO
        </p>
      )}
    </div>
  );
};

export const RadioButtonLojas = ({
  options,
  form,
  setValuesWatch,
  isSubmit,
  onChange: handleChange,
}: {
  options: {
    label: string;
    value: string;
  }[];
  form: FormApi<AnyObject>;
  setValuesWatch: React.Dispatch<React.SetStateAction<typeof valuesWatchConfig>>;
  isSubmit: boolean;
  onChange: (value: string) => void;
}) => {
  const dimensions = useDimensions();
  const [validate, setValidate] = useState<boolean>(true);

  const onChange = (e: RadioChangeEvent) => {
    handleChange(e.target.value);
    form.change('loja', e.target.value);
    setValuesWatch((prev) => ({
      ...prev,
      loja: e.target.value,
    }));
  };

  useEffect(() => {
    if (isSubmit && !form.getFieldState('loja')?.value) {
      setValidate(false);
    } else {
      setValidate(true);
    }
  }, [isSubmit, form.getFieldState('loja')?.value]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        height: options.length >= 9 ? '250px' : '',
        overflowY: options.length > 9 ? 'scroll' : 'auto',
      }}
    >
      <p style={{ margin: 0 }}>Selecione o PDV de acordo com o canal e endereço se sua preferência: </p>
      <Radio.Group
        name="loja"
        id="loja"
        onChange={onChange}
        value={form.getFieldState('loja')?.value}
        style={{
          ...(validate
            ? {}
            : {
                border: '1px solid #dc3545',
                boxShadow: '0 0 0 1px #dc3545',
                opacity: 0.5,
              }),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          border: '2px solid #e8e8e8',
          borderRadius: '2px',
          padding: '2px 5px',
        }}
      >
        {options.map((option, idx) =>
          dimensions.isMobile ? (
            <div>
              <Radio
                style={{ background: idx % 2 === 0 ? '#e6f4ff' : '#cce9ff' }}
                key={`radio-btn-${idx}`}
                value={option.value}
              />
              <p style={{ background: idx % 2 === 0 ? '#e6f4ff' : '#cce9ff' }}>{option.label}</p>
            </div>
          ) : (
            <Radio
              style={{ background: idx % 2 === 0 ? '#e6f4ff' : '#cce9ff' }}
              key={`radio-btn-${idx}`}
              value={option.value}
            >
              {option.label}
            </Radio>
          ),
        )}
      </Radio.Group>
      {!validate && (
        <p
          style={{
            color: '#dc3545',
            alignSelf: 'end',
            fontSize: '11px',
            fontWeight: 'bold',
          }}
        >
          CAMPO OBRIGATÓRIO
        </p>
      )}
    </div>
  );
};

export const MeuDiaNoPdv = () => {
  const dimensions = useDimensions();
  const meudiaPdvTableApi = useClient('MeuDiaPdvTableAll');
  const createVaga = useClient('MeuPdvParamsMutation');
  const [table, setTable] = useState<TableMeudiaPdv[]>([]);
  const [endereco, setEndereco] = useState<typeof enderecoDefaultValues>(enderecoDefaultValues);
  const [valuesWatch, setValuesWatch] = useState<typeof valuesWatchConfig>(valuesWatchConfig);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [isModalSuccess, setIsModalSucess] = useState<boolean>(false);
  const [result, setResult] = useState<Record<string, any>>({});
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const downloadRegulamento = () => {
    var element = document.createElement('a');
    element.setAttribute('href', '/files/Regulamento_Meu_Dia_no_PDV_Black_Friday_2024.pdf');
    element.setAttribute('download', 'Regulamento_Meu_Dia_no_PDV_Black_Friday_2024.pdf');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const handleTable = async () => {
    await meudiaPdvTableApi
      .fetch({
        appendToFragment: tableFragment,
      })
      .then((ctx) => {
        setTable(ctx.result.table);
      });
  };

  useEffect(() => {
    handleTable();
  }, []);

  useEffect(() => {
    const loja = table.find((c) => `${c._id}-${c.loja}` === valuesWatch.loja);

    setEndereco((prev) => ({
      ...prev,
      endereco: loja?.endereco as string,
      complemento: loja?.complemento as string,
      cep: loja?.cep as string,
      logradouro: loja?.endereco as string,
      tipo: loja?.tipo as string,
    }));
  }, [valuesWatch.loja]);

  const regionalOptions = useMemo(
    () =>
      uniqBy(table, 'regional').map((c) => ({
        value: c.regional,
        label: c.regional.toUpperCase(),
      })),
    [table],
  );

  const ufOptions = useMemo(() => {
    const ufFilter = table.filter((c) => normalize(c.regional) === normalize(valuesWatch.regional));
    const ufUniq = uniqBy(ufFilter, 'uf').map((c) => ({
      value: c.uf,
      label: c.uf?.toUpperCase(),
    }));

    return ufUniq;
  }, [valuesWatch.regional]);

  const municipioOptions = useMemo(() => {
    const municipiosFilter = table.filter(
      (c) => normalize(c.regional) === normalize(valuesWatch.regional) && c.uf === valuesWatch.uf,
    );

    const municipiosUniq = municipiosFilter.map((c) => ({
      value: c.municipio,
      label: normalize(c.municipio),
    }));

    return uniqBy(municipiosUniq, 'label');
  }, [valuesWatch.uf]);

  const lojaOptions = useMemo(() => {
    const lojasFilter = table.filter(
      (c) =>
        normalize(c.municipio) === normalize(valuesWatch.municipio) &&
        (normalize(c.canal) === normalize(valuesWatch.canal) || !valuesWatch.canal),
    );

    const vagasQtd = lojasFilter.filter((l) => l.manha1011 + l.manha1112 + l.tarde1415 + l.tarde1516 + l.tarde1617 > 0);
    const lojasUniq = vagasQtd.map((c) => ({
      value: `${c._id}-${c.loja}`,
      label: `${c.canal} - ${c.bairro} - ${c.loja}`.toUpperCase(),
    }));

    return lojasUniq.sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [valuesWatch.municipio, valuesWatch.bairro]);

  const vagasOptions = useMemo(() => {
    const mapped: Record<string, string> = {
      '10-11': 'manha1011',
      '11-12': 'manha1112',
      '14-15': 'tarde1415',
      '15-16': 'tarde1516',
      '16-17': 'tarde1617',
    };

    const options = [...vagasMock];

    if (!valuesWatch.loja) return options;

    return options.map((l) => {
      const loja = table.find((t) => `${t._id}-${t.loja}` === valuesWatch.loja) as any;

      const vaga = mapped[l.value];
      const qtdVagaDisponiveis = loja[vaga];

      return {
        ...l,
        disabled: qtdVagaDisponiveis <= 0,
        vagas: qtdVagaDisponiveis,
      };
    });
  }, [valuesWatch.loja]);

  const diretoriaOptions = useMemo(
    () =>
      [
        { value: 'CFO', label: 'Chief Financial Officer' },
        { value: 'Legal & Corporate Affairs', label: 'Legal & Corporate Affairs' },
        { value: 'Investor Relations Office', label: 'Investor Relations Office' },
        { value: 'People, Culture & Organization', label: 'People, Culture & Organization' },
        {
          value: 'Regulatory, Institutional and Press Relations',
          label: 'Regulatory, Institutional and Press Relations',
        },
        { value: 'Business Support Officer', label: 'Business Support Officer' },
        { value: 'New Business & Innovation', label: 'New Business & Innovation' },
        { value: 'Customer Experience & Ouvidoria', label: 'Customer Experience & Ouvidoria' },
        { value: 'CRO', label: 'Chief Revenue Officer' },
        { value: 'CTO', label: 'Chief Technology Officer' },
        { value: 'CIO', label: 'Chief Information Officer' },
      ].sort((a, b) => (a.label > b.label ? 1 : -1)),
    [],
  );

  return (
    <S.Wrapper isMobile={dimensions.isMobile}>
      <Modal
        title='Sua inscrição para o "Meu Dia no PDV" foi     realizada com sucesso!'
        visible={isModalSuccess}
        okText="Fechar"
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => setIsModalSucess(false)}
      >
        <p>Sua vaga foi preenchida com sucesso! Tire uma foto para guardar o endereço e horário.</p>
        <p>{`Canal: ${result?.canal}`}</p>
        <p>{`Endereço: ${result?.endereco}, ${result?.complemento} - ${result?.municipio}`}</p>
        <p>Data: 27/11/24</p>
        <p>{`Horário: ${vagasMock.find((v) => v?.value === result?.vaga)?.label}`}</p>
        <p>Se quiser cancelar e disponibilizar sua vaga, entre em contato com tdvianna@timbrasil.com.br</p>
        <p>Aproveite o “Meu Dia no PDV!”</p>
      </Modal>
      <S.Container>
        <S.MeuDiaPdvWrapper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <div style={{ width: '40%' }}>
              <img
                style={{ justifySelf: 'start' }}
                src="/defaultImages/meudianopdv.png"
                alt="meu-dia-no-pdv-logo"
                width="150px"
                height="150px"
              />
            </div>
            <div style={{ width: '60%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              <div>
                <h1 style={{ fontWeight: 'bold', textAlign: 'center' }}>MEU DIA NO PDV</h1>
                <h2>Escolha seu PDV, horário e participe!</h2>
              </div>
            </div>
          </div>

          {!table.length ? (
            <LoadingIndicator small />
          ) : (
            <Former
              config={({ form }) => {
                form.registerField('vaga', () => {}, {});
                form.registerField('loja', () => {}, {});
                form.registerField('canal', () => {}, {});
                form.registerField('bairro', () => {}, {});

                return {
                  fields: [
                    {
                      className: 'header',
                      inline: !dimensions.isMobile,
                      list: [
                        {
                          name: 'regional',
                          label: 'Regional',
                          type: 'select',
                          required: true,
                          options: regionalOptions,
                          afterChange(value) {
                            setValuesWatch((prev) => ({
                              ...prev,
                              regional: value,
                            }));
                          },
                        },
                        {
                          name: 'uf',
                          label: 'UF',
                          type: 'select',
                          required: true,
                          options: ufOptions,
                          maxWidth: '80px',
                          afterChange(value) {
                            setValuesWatch((prev) => ({
                              ...prev,
                              uf: value,
                            }));
                          },
                        },
                        {
                          name: 'municipio',
                          label: 'Municipio',
                          type: 'select',
                          required: true,
                          options: municipioOptions,
                          afterChange(value) {
                            setValuesWatch((prev) => ({
                              ...prev,
                              municipio: value,
                            }));
                          },
                        },
                      ],
                    },
                    {
                      list: [
                        {
                          name: 'loja',
                          custom: {
                            render: () => (
                              <RadioButtonLojas
                                options={lojaOptions}
                                form={form as FormApi<AnyObject>}
                                setValuesWatch={setValuesWatch}
                                isSubmit={isSubmit}
                                onChange={(value) => {
                                  const loja = table.find((t) => `${t._id}-${t.loja}` === value);
                                  form.change('canal', loja?.canal);
                                  form.change('bairro', loja?.bairro);
                                }}
                              />
                            ),
                          },
                        },
                      ],
                    },
                    {
                      inline: !dimensions.isMobile,
                      list: [
                        {
                          name: 'endereco',
                          label: 'Endereço',
                          type: 'text',
                          required: true,
                          disabled: true,
                          defaultValue: endereco.endereco,
                        },
                        {
                          name: 'complemento',
                          label: 'Complemento',
                          type: 'text',
                          defaultValue: endereco.complemento,
                          maxWidth: '350px',
                          disabled: true,
                        },
                      ],
                    },
                    {
                      inline: !dimensions.isMobile,
                      list: [
                        {
                          name: 'cep',
                          label: 'Cep',
                          type: 'text',
                          defaultValue: endereco.cep,
                          required: false,
                          disabled: true,
                        },
                        {
                          name: 'tipo',
                          label: 'Tipo',
                          type: 'text',
                          defaultValue: endereco.tipo,
                          required: false,
                          disabled: true,
                        },
                      ],
                    },
                    {
                      inline: !dimensions.isMobile,
                      list: [
                        {
                          name: 'vaga',
                          custom: {
                            render: () => (
                              <RadioButtonCustomVaga
                                setIsComplete={setIsComplete}
                                isSubmit={isSubmit}
                                options={vagasOptions}
                                form={form as FormApi<AnyObject>}
                              />
                            ),
                          },
                        },
                      ],
                    },
                    isComplete
                      ? {
                          inline: true,
                          list: [
                            {
                              name: 'nome',
                              label: 'Nome',
                              type: 'text',
                              required: true,
                            },
                          ],
                        }
                      : null,
                    isComplete
                      ? {
                          inline: true,

                          list: [
                            {
                              name: 'matricula',
                              label: 'Matricula',
                              type: 'text',
                              required: true,
                            },
                          ],
                        }
                      : null,
                    isComplete
                      ? {
                          inline: true,
                          list: [
                            {
                              name: 'celular',
                              label: 'Celular',
                              type: 'text',
                              required: true,
                              mask: '##-#####-####',
                              validate: (value: any) => {
                                const format = /\d{2}-\d{5}-\d{4}/;
                                if (!format.test(value)) return 'Número inválido';
                                return undefined;
                              },
                            },
                          ],
                        }
                      : null,
                    isComplete
                      ? {
                          inline: true,
                          list: [
                            {
                              name: 'email',
                              label: 'E-mail',
                              type: 'text',
                              required: true,
                            },
                          ],
                        }
                      : null,
                    isComplete
                      ? {
                          inline: true,
                          list: [
                            {
                              name: 'diretoria',
                              label: 'Diretoria',
                              type: 'select',
                              defaultValue: '',
                              required: true,
                              options: diretoriaOptions,
                            },
                          ],
                        }
                      : null,
                    isComplete
                      ? {
                          inline: true,
                          list: [
                            {
                              name: 'descricaoTrabalho',
                              label:
                                'Descreva em poucas palavras com o que você trabalha e como seu trabalho impacta a força de vendas e a experiência final do cliente.',
                              type: 'textarea',
                              required: true,
                              extraProps: {
                                rows: 5,
                                maxLength: 200,
                              },
                              validate: (value: any) => {
                                const format = /[#%&]/;
                                if (format.test(value)) return 'Os caracteres #%& não são permitidos';

                                if (value) return !(value.length > 200) ? undefined : 'Máximo de 200 caracteres';

                                return undefined;
                              },
                            },
                          ],
                        }
                      : null,
                  ],
                  submitButton: {
                    label: 'Enviar',
                    onClick: () => setIsSubmit(true),
                    disabled: !isComplete,
                  },
                };
              }}
              onSubmit={async ({ data }) => {
                const requiredValues = [data.loja, data.vaga];

                if (!requiredValues.every(Boolean)) {
                  return;
                }

                setResult(data);
                const result = await createVaga.fetch({
                  variables: data,
                  appendToFragment: `
                  nome
                  celular
                `,
                });
                await handleTable();
                setEndereco(enderecoDefaultValues);
                setValuesWatch(valuesWatchConfig);
                setIsComplete(false);
                setIsSubmit(false);
                if (result?.errors?.length && result?.errors?.length > 0) {
                  return false;
                }
                setIsModalSucess(true);
              }}
            />
          )}
          <S.ButtonRegulamento>
            <Button onClick={downloadRegulamento}>Baixe o regulamento</Button>
          </S.ButtonRegulamento>
        </S.MeuDiaPdvWrapper>
      </S.Container>
    </S.Wrapper>
  );
};

const tableFragment = `
MeuDiaPdvTableAll {
  table {
    _id
    uf
    regional
    municipio
    canal
    bairro
    loja
    endereco
    complemento
    cep
    tipo
    manha1011
    manha1112
    tarde1415
    tarde1516
    tarde1617
  }
}
`;
